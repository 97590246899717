<template>
  <div class="page">
    <transition-group id="components" name="component" tag="div" class="component_list" :class="[id, viewClass]">
      <ObjectLoader :plkparams="component" :instance="getInstance(component.type)" v-for="(component, index) in componentsWithInstance" :key="`${Math.random()}-${index}`"/>
    </transition-group>
    <div class="watermark"><a target="_blank" href="https://plakastudio.com">Powered by <i class="plk icon-plk">&#xe805;</i> Plaka Studio</a></div>
  </div>
</template>

<script>
import Vue from 'vue'
import { executeActions } from './commons/actions'
import ObjectLoader from './objectLoaders/base/ObjectLoader'
import { LOG_SUCCESS, LOG_ERROR } from '@/services'
import { Store, USER } from '@/store'
import { ABOUT_PATH } from '@/enviroment/app'

export default {
  name: 'viewer',
  components: {
    ObjectLoader
  },
  data () {
    return {
      instances: {},
      view: {},
      id: this.$route.params.id || 'index',
      executingActions: true
    }
  },
  metaInfo () {
    return this.view.metaInfo || {}
  },
  beforeDestroy () {
    this.$servicesPLK.event.emitEvent(this.$servicesPLK.event.BASIC_ACTIONS.DESTROY, {})
  },
  computed: {
    allUsers () {
      return Store.getters[USER.GETTERS.GET_ALL_USERS]()
    },
    components () {
      return this.view.components || []
    },
    viewClass () {
      return this.view.viewClass || ''
    },
    componentsWithInstance () {
      return this.executingActions ? [] : this.components.filter(component => this.getInstance(component.type))
    }
  },
  watch: {
    components () {
      this.loadComponents()
    },
    $route (to, from) {
      window.scrollTo(0, 0)
      Vue.set(this, 'id', to.params.id)
      this.$servicesPLK.event.emitEvent(this.$servicesPLK.event.BASIC_ACTIONS.DESTROY, {})
      this.onInitComponent()
    },
    async allUsers () {
      if (Object.keys(this.allUsers).length === 0) {
        let connections = await this.$servicesPLK.connection.getConnections(this.$servicesPLK.connection.TYPE.PLAKA)
        if (connections.length > 0) {
          window.location.reload()
        }
      }
    }
  },
  methods: {
    onInitComponent () {
      this.loadView()
    },
    loadView () {
      Vue.set(this, 'executingActions', true)
      this.$servicesPLK.component.getView(this.id).then(async (view = {}) => {
        this.$servicesPLK.logger.log(this.id, `${this.id}-loadPage-200`, LOG_SUCCESS)
        Vue.set(this, 'view', view)
        await executeActions(view.actions)
        Vue.set(this, 'executingActions', false)
      }).catch(() => {
        this.$servicesPLK.logger.log(this.id, `${this.id}-loadPage-404`, LOG_ERROR)
        this.$router.push({ name: ABOUT_PATH, query: this.$route.query })
      })
    },
    getInstance (type) {
      return this.instances[this.normalizeType(type)]
    },
    normalizeType (type) {
      return this.$servicesPLK.component.normalizeType(type)
    },
    loadComponents () {
      this.components.forEach((component) => {
        if (!component.type) {
          console.warn('No found type component')
        } else if (component.type && !this.instances[component.type]) {
          this.$servicesPLK.component.loadComponent(component.type, this.instances, this.normalizeType(component.type))
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .page {
    .watermark {
      a {
        color: black;
        text-decoration: none;
      }
      position: fixed;
      right: 5px;
      bottom: 0;
    }

    .component_list {
      height: 100vh;
    }

    /* &-leave-active in <2.1.8 */
    /* &-enter { opacity: 0 } */
    /* &-enter { transform: scale(0.95) } */
    .component {
      &-enter-active, &-leave-active {
        transition: opacity 1s;
      }

      &-enter {
        opacity: 0;
      }

      &-leave-to {
        opacity: 0;
        display: none;
      }
    }
  }
</style>
